import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SnakbarService } from 'src/app/services/snakbar.service';
import { StatisticService } from 'src/app/services/statistic.service';
import { GlobalConstants } from 'src/app/shared/global-constanst';
import * as moment from "moment";
import { LocationService } from 'src/app/services/location.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.scss']
})
export class CashFlowComponent implements OnInit {

  defaultLocation = { id: null, name: 'Усі' };
  locations;
  selectedLocation = this.defaultLocation;

  selectedStartDate;
  selecedEndDate;


  incomeDataSource;
  outcomeDataSource;
  utilizationDataSource;
  incomeColumns: string[] = ['productName', 'tags', 'salesCount', 'fullDiscountSalesCount', 'totalAmount'];
  outcomeColumns: string[] = ['expenseName', 'expensesCount', 'totalAmount'];
  utilizationColumns: string[] = ['name', 'totalCount', 'totalAmount'];

  incomePanelOpenState = false;
  outcomePanelOpenState = false;
  utilizationPanelOpenState = false;

  selected: any = { startDate: null, endDate: null };

  startMoney;
  endMoney;
  totalIncome;
  totalOutcome;
  salesDiff;
  utilizationTotalAmount;


  constructor(
    private locationService: LocationService,
    private statisticService: StatisticService,
    private ngxService: NgxUiLoaderService,
    private snakbarService: SnakbarService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.locationService.get().subscribe((response: any) => {
      var filteredOrderedLocation = response.filter(l => l.isWarehouse == false)
        .sort((n1, n2) => {
          if (n1.order > n2.order) {
            return 1;
          }

          if (n1.order < n2.order) {
            return -1;
          }

          return 0;
        });
      filteredOrderedLocation.splice(0, 0, this.defaultLocation);
      this.locations = filteredOrderedLocation;

      this.changeDetectorRef.detectChanges();
    }
      ,
      (error) => {
        this.ngxService.stop();
        this.snakbarService.openSnackBar(error, GlobalConstants.error);
      });


    this.selected.startDate = moment().subtract(1, 'months').startOf('day');
    this.selected.endDate = moment().endOf('day');
    this.dateChanged({ startDate: this.selected.startDate, endDate: this.selected.endDate });
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

  locationChagedAction(location: MatSelectChange) {
    this.selectedLocation = {id: location.value.id, name: location.value.name};
    this.getItems();
  }

  dateChanged(event) {
    if (!event.startDate || !event.endDate) {
      return;
    }

    let start = new Date(event.startDate);
    let end = new Date(event.endDate);
    end.setUTCHours(23, 59, 59, 999);

    this.selectedStartDate = start.toISOString();
    this.selecedEndDate = end.toISOString();

    this.getItems();
  }

  getItems() {
    this.changeDetectorRef.detectChanges();
    this.ngxService.start();
    this.statisticService.getCashFlow(this.selectedLocation.id, this.selectedStartDate, this.selecedEndDate).subscribe((response: any) => {
      this.ngxService.stop();


      this.startMoney = response.startPeriodMoney.toFixed(2);
      this.endMoney = response.endPeriodMoney.toFixed(2);

      this.totalIncome = response.income.toFixed(2);
      this.totalOutcome = response.outcome.toFixed(2);
      this.salesDiff = response.salesDiff.toFixed(2);

      this.utilizationTotalAmount = response.utilizationTotalAmount.toFixed(2);

      let sortedIncomes = response.incomes.sort((n1, n2) => {
        if (n1.categoryName > n2.categoryName) {
          return 1;
        }

        if (n1.categoryName < n2.categoryName) {
          return -1;
        }

        return 0;
      });

      let groupedIncomes = sortedIncomes.reduce((r, a) => {
        r[a.categoryName] = [...r[a.categoryName] || [], a];
        return r;
      }, {});

      let incomeResult = [];

      Object.values(groupedIncomes)
        .map((items, index) => {
          var arr = <Array<any>>items;
          let item = {
            isGroupBy: true,
            categoryName: items[0].categoryName,
            totalAmount: arr.reduce((sum, current) => sum + current.totalAmount, 0).toFixed(2),
          };
          incomeResult.push(item);

          for (var i = 0; i < arr.length; i++) {
            arr[i].totalAmount = arr[i].totalAmount.toFixed(2);
            incomeResult.push(arr[i]);
          }
        });

      this.incomeDataSource = incomeResult;


      let sortedOutcomes = response.outcomes.sort((n1, n2) => {
        if (n1.expenseFinancialType > n2.expenseFinancialType) {
          return 1;
        }

        if (n1.expenseFinancialType < n2.expenseFinancialType) {
          return -1;
        }

        return 0;
      });

      let groupedOutcomes = sortedOutcomes.reduce((r, a) => {
        r[a.expenseFinancialType] = [...r[a.expenseFinancialType] || [], a];
        return r;
      }, {});

      let outcomeResult = [];

      Object.values(groupedOutcomes)
        .map((items, index) => {
          var arr = <Array<any>>items;
          let item = {
            isGroupBy: true,
            expenseFinancialType: items[0].expenseFinancialType,
            totalAmount: arr.reduce((sum, current) => sum + current.totalAmount, 0).toFixed(2),
          };
          outcomeResult.push(item);

          for (var i = 0; i < arr.length; i++) {
            arr[i].totalAmount = arr[i].totalAmount.toFixed(2);
            outcomeResult.push(arr[i]);
          }
        });

      this.outcomeDataSource = outcomeResult;

      var utilizations = response.utilizations;
      var byStorageItem = utilizations.filter(i => i.storageItemName !== null);
      var byProduct = utilizations.filter(i => i.productName !== null);

      let utilizationResult = [];

      let storageitemHeader = {
        isGroupBy: true,
        type: 'Продукти',
        totalAmount: byStorageItem.reduce((sum, current) => sum + current.totalAmount, 0).toFixed(2),
      };

      utilizationResult.push(storageitemHeader);

      for (let index = 0; index < byStorageItem.length; index++) {
        const element = byStorageItem[index];
        element.name = element.storageItemName;
        element.totalCount = element.count;
        element.totalAmount = element.totalAmount.toFixed(2);
        utilizationResult.push(element);
      }

      let productsHeader = {
        isGroupBy: true,
        type: 'Товари',
        totalAmount: byProduct.reduce((sum, current) => sum + current.totalAmount, 0).toFixed(2),
      };

      utilizationResult.push(productsHeader);

      for (let index = 0; index < byProduct.length; index++) {
        const element = byProduct[index];
        element.name = element.productName;
        element.totalCount = element.count;
        element.totalAmount = element.totalAmount.toFixed(2);
        utilizationResult.push(element);
      }

      this.utilizationDataSource = utilizationResult;
    },
      (error) => {
        this.ngxService.stop();
      });
  }

}

<mat-toolbar color="primary">
    <mat-toolbar-row fxLayout="row">
        <span>Додати ПРРО</span>
        <span class="spacer"></span>
        <button class="mat-dialog-close" mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="keyForm">
        <div fxFlex fxLayout="column">
            <mat-form-field appearance="standard" fxFlex>
                <mat-label>Назва ФОП</mat-label>
                <input matInput formControlName="fopName" required>
                <mat-error *ngIf="keyForm.controls.fopName.touched && keyForm.controls.fopName.invalid">
                    <span *ngIf="keyForm.controls.fopName.errors.required">This filed is mandatory</span>
                </mat-error> 
            </mat-form-field>

            <input type="file" accept=".jks,.dat" class="file-input"
            (change)="onKeySelected($event)" #keyUpload>

            <div class="file-upload">
                Файл ключа: {{keyName }}
                <button mat-mini-fab color="primary" class="upload-btn"
                (click)="keyUpload.click()">
                <mat-icon>attach_file</mat-icon>
                </button>
            </div>
            <br/>
            <input type="file" accept=".cer" class="file-input"
            (change)="onCertSelected($event)" #certUpload>
            <div class="file-upload">
                Файл сертифікату: {{certName}}
                <button mat-mini-fab color="primary" class="upload-btn"
                (click)="certUpload.click()">
                <mat-icon>attach_file</mat-icon>
                </button>
            </div>
            <mat-label>Щоб отримати сертифікат, перейдіть за <a href="https://id.gov.ua/sign" target="_blank">посиланням</a>, зчитайте ключ та завантажте сертифікат ЕЦП (ДСТУ 4145)</mat-label>
            <br/>
            <mat-form-field appearance="standard" fxFlex>
                <mat-label>Пароль</mat-label>
                <input matInput formControlName="password" type="password" required [type]="password ? 'password': 'text'">
                <button type="button" mat-icon-button matSuffix (click)="password = !password" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="password" >
                    <mat-icon>{{password ? 'visibility_off': 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="keyForm.controls.password.touched && keyForm.controls.password.invalid">
                    <span *ngIf="keyForm.controls.password.errors.required">This filed is mandatory</span>
                </mat-error> 
            </mat-form-field>
            <mat-slide-toggle formControlName="sendTestChecks">Режим тестування</mat-slide-toggle>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" >
    <button mat-raised-button color="primary" type="submit" (click)="handleSubmit()" [disabled]="!(keyForm.valid && keyForm.dirty)">
        <mat-icon>save</mat-icon> Signup
    </button>
    <button mat-raised-button color="warn" mat-dialog-close>
        <mat-icon>cancel</mat-icon>Close
    </button>
</mat-dialog-actions>
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LocationService } from 'src/app/services/location.service';
import { SnakbarService } from 'src/app/services/snakbar.service';
import { StatisticService } from 'src/app/services/statistic.service';
import { GlobalConstants } from 'src/app/shared/global-constanst';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-full-report',
  templateUrl: './full-report.component.html',
  styleUrls: ['./full-report.component.scss']
})
export class FullReportComponent implements OnInit {

  defaultLocation = { id: null, name: 'Усі' };
  locations;
  selectedLocation = this.defaultLocation;

  selectedStartDate;
  selecedEndDate;
  selected: any = { startDate: null, endDate: null };

  constructor(private locationService: LocationService,
    private statisticService: StatisticService,
    private ngxService: NgxUiLoaderService,
    private snakbarService: SnakbarService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.locationService.get().subscribe((response: any) => {
      var filteredOrderedLocation = response.filter(l => l.isWarehouse == false)
        .sort((n1, n2) => {
          if (n1.order > n2.order) {
            return 1;
          }

          if (n1.order < n2.order) {
            return -1;
          }

          return 0;
        });
      filteredOrderedLocation.splice(0, 0, this.defaultLocation);
      this.locations = filteredOrderedLocation;

      this.changeDetectorRef.detectChanges();
    }
      ,
      (error) => {
        this.ngxService.stop();
        this.snakbarService.openSnackBar(error, GlobalConstants.error);
      });


    this.selected.startDate = moment().subtract(1, 'months').startOf('day');
    this.selected.endDate = moment().endOf('day');
    this.dateChanged({ startDate: this.selected.startDate, endDate: this.selected.endDate });
  }

  locationChagedAction(location: MatSelectChange) {
    this.selectedLocation = {id: location.value.id, name: location.value.name};
  }

  dateChanged(event) {
    if (!event.startDate || !event.endDate) {
      return;
    }

    let start = new Date(event.startDate);
    let end = new Date(event.endDate);
    end.setUTCHours(23, 59, 59, 999);

    this.selectedStartDate = start.toISOString();
    this.selecedEndDate = end.toISOString();
  }

  handleReportExport(){
    this.ngxService.start();
    this.statisticService.getFullReport(this.selectedLocation.id, this.selectedStartDate, this.selecedEndDate).subscribe((response: any) => {
      this.ngxService.stop();

      const blob = new Blob([response], {
        type: "application/octet-stream"
      });
      saveAs(blob, "file.xlsx");
    },
    (error) => {
      this.ngxService.stop();
    });
  }

}

<mat-toolbar color="primary">
    <mat-toolbar-row fxLayout="row">
        <span>Forgot password?</span>
        <span class="spacer"></span>
        <button class="mat-dialog-close" mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="forgotPasswordForm">
        <div fxFlex fxLayout="column">
        

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" required>
                <mat-error *ngIf="forgotPasswordForm.controls.email.touched && forgotPasswordForm.controls.email.invalid">
                    <span *ngIf="forgotPasswordForm.controls.email.errors.required">This filed is mandatory</span>
                    <span *ngIf="forgotPasswordForm.controls.email.errors.pattern">This filed is invalid</span>
                </mat-error> 
            </mat-form-field>

        </div>
    </form>
</mat-dialog-content>


<mat-dialog-actions align="end" >
    <button mat-raised-button color="primary" type="submit" (click)="handleSubmit()" [disabled]="!(forgotPasswordForm.valid && forgotPasswordForm.dirty)">
        <mat-icon>send</mat-icon> Send password
    </button>
    <button mat-raised-button color="warn" mat-dialog-close>
        <mat-icon>cancel</mat-icon>Close
    </button>
</mat-dialog-actions>
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SnakbarService } from 'src/app/services/snakbar.service';
import { StatisticService } from 'src/app/services/statistic.service';
import { GlobalConstants } from 'src/app/shared/global-constanst';
import * as moment from "moment";
import { LocationService } from 'src/app/services/location.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  defaultLocation = { id: null, name: 'Усі' };
  locations;
  selectedLocation = this.defaultLocation;

  selectedStartDate;
  selecedEndDate;

  selected: any = { startDate: null, endDate: null };

  productsDataSource;
  productsColumns: string[] = ['productName', 'tags', 'earnedAmount', 'totalAmount'];


  constructor(
    private locationService: LocationService,
    private statisticService: StatisticService,
    private ngxService: NgxUiLoaderService,
    private snakbarService: SnakbarService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.locationService.get().subscribe((response: any) => {
      var filteredOrderedLocation = response.filter(l => l.isWarehouse == false)
        .sort((n1, n2) => {
          if (n1.order > n2.order) {
            return 1;
          }

          if (n1.order < n2.order) {
            return -1;
          }

          return 0;
        });
      filteredOrderedLocation.splice(0, 0, this.defaultLocation);
      this.locations = filteredOrderedLocation;

      this.changeDetectorRef.detectChanges();
    }
      ,
      (error) => {
        this.ngxService.stop();
        this.snakbarService.openSnackBar(error, GlobalConstants.error);
      });


    this.selected.startDate = moment().subtract(1, 'months').startOf('day');
    this.selected.endDate = moment().endOf('day');
    this.dateChanged({ startDate: this.selected.startDate, endDate: this.selected.endDate });
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

  locationChagedAction(location: MatSelectChange) {
    this.selectedLocation = {id: location.value.id, name: location.value.name};
    this.getItems();
  }

  dateChanged(event) {
    if (!event.startDate || !event.endDate) {
      return;
    }

    let start = new Date(event.startDate);
    // start.setUTCHours(0, 0, 0, 0);
    let end = new Date(event.endDate);
    end.setUTCHours(23, 59, 59, 999);

    this.selectedStartDate = start.toISOString();
    this.selecedEndDate = end.toISOString();

    this.getItems();
  }

  getItems() {
    this.changeDetectorRef.detectChanges();
    this.ngxService.start();
    this.statisticService.getProducts(this.selectedLocation.id, this.selectedStartDate, this.selecedEndDate).subscribe((response: any) => {
      this.ngxService.stop();

      let sortedIncomes = response.sort((n1, n2) => {
        if (n1.categoryName > n2.categoryName) {
          return 1;
        }

        if (n1.categoryName < n2.categoryName) {
          return -1;
        }

        return 0;
      });

      let groupedIncomes = sortedIncomes.reduce((r, a) => {
        r[a.categoryName] = [...r[a.categoryName] || [], a];
        return r;
      }, {});

      let incomeResult = [];

      Object.values(groupedIncomes)
        .map((items, index) => {
          var arr = <Array<any>>items;
          let item = {
            isGroupBy: true,
            categoryName: items[0].categoryName,
            totalAmount: arr.reduce((sum, current) => sum + current.totalAmount, 0).toFixed(2),
          };
          incomeResult.push(item);

          var sorted = arr.sort((a, b) => b.totalAmount - a.totalAmount)

          for (var i = 0; i < sorted.length; i++) {
            sorted[i].earnedAmount = sorted[i].earnedAmount.toFixed(2);
            sorted[i].totalAmount = sorted[i].totalAmount.toFixed(2);
            incomeResult.push(sorted[i]);
          }
        });

      this.productsDataSource = incomeResult;
    
    },
      (error) => {
        this.ngxService.stop();
      })
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url = environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  signup(data:any){
    return this.httpClient.post(this.url + "/users/registerClient", data, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  resetPassword(data:any){
    return this.httpClient.post(this.url + "/users/sendResetToken", data, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  login(data:any){
    return this.httpClient.post(this.url + "/users/authenticate", data, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  refreshToken(data:any){
    return this.httpClient.post(this.url + "/users/refresh-token", data, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  async tryRefreshingTokens(token: string): Promise<boolean> {
    const refreshToken: string = localStorage.getItem("refreshToken");
    if (!token || !refreshToken) { 
      return false;
    }
    
    const email: string = localStorage.getItem("email");
    var data = {
        email: email,
        token: token,
        refreshToken: refreshToken
      };
  
    let isRefreshSuccess: boolean;
    const refreshRes = await new Promise<any>((resolve, reject) => {
      this.refreshToken(data).subscribe({
        next: (res: any) => resolve(res),
        error: (_) => { reject; isRefreshSuccess = false;}
      });
    });
    localStorage.setItem("token", refreshRes.token);
    localStorage.setItem("email", refreshRes.email);
    localStorage.setItem("refreshToken", refreshRes.refreshToken);
    isRefreshSuccess = true;
    return isRefreshSuccess;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { SnakbarService } from '../services/snakbar.service';
import { UserService } from '../services/user.service';
import { GlobalConstants } from '../shared/global-constanst';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  password = true;
  loginForm:any = FormGroup;
  responseMessage:any;

  constructor(private formBuilder:FormBuilder,
    private userService:UserService,
    private dialogRef:MatDialogRef<ForgotPasswordComponent>,
    private ngxService:NgxUiLoaderService,
    private snakbarService:SnakbarService,
    private router:Router) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email:[null,[Validators.required]],
      password:[null,[Validators.required]]
    });
  }

  handleSubmit(){
    this.ngxService.start();
    var formData = this.loginForm.value;

    var data = {
      email: formData.email,
      password: formData.password,
      obtainRefreshToken: true
    };

    this.userService.login(data).subscribe((response:any) =>{
      this.ngxService.stop();
      this.dialogRef.close();
      this.responseMessage = response?.message;
      localStorage.setItem("token", response.token);
      localStorage.setItem("refreshToken", response.refreshToken);
      localStorage.setItem("email", response.email);
      localStorage.setItem("userData", JSON.stringify(response));
      this.router.navigate(['admin/finance']);
    }, 
    (error) =>{
      this.ngxService.stop();
    })
  }
}

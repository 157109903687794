import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FullComponent } from './layouts/full/full.component';
import { AuthGuardService } from './services/auth-guard.service';
import { FinanceComponent } from './finance/finance.component';
import { StatisticComponent } from './statistic/statistic.component';
//import { FiscalizationComponent } from './fiscalization/fiscalization.component';
import { PnlComponent } from './statistic/pnl/pnl.component';
import { CashFlowComponent } from './statistic/cash-flow/cash-flow.component';
import { ProductComponent } from './statistic/product/product.component';
import { LoginComponent } from './login/login.component';
import { FullReportComponent } from './statistic/full-report/full-report.component';
import { CashiersComponent } from './fiscalization/cashiers/cashiers.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { SuccessComponent } from './feedback/success/success.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'feedback', component: FeedbackComponent },
  { path: 'feedback/success', component: SuccessComponent },
  {
    path: 'admin',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/admin/finance',
        pathMatch: 'full',
      },
      {
        path: 'finance',
        component: FinanceComponent
      },
      {
        path: 'fiscalization',
        loadChildren: () => import('./fiscalization/fiscalization.module').then(m => m.FiscalizationModule),
      },
      {
        path: 'statistic/pnl',
        component: PnlComponent
      },
      {
        path: 'statistic/cashFlow',
        component: CashFlowComponent
      },
      {
        path: 'statistic/product',
        component: ProductComponent
      },
      {
        path: 'statistic/full-report',
        component: FullReportComponent
      },
    ],
    canActivateChild: [AuthGuardService],
    canActivate: [AuthGuardService]
  },
  { path: '**', component: HomeComponent },

  { path: "login", component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export class GlobalConstants{


    public static genericError: string = "Something went wrong";

    public static nameRegex: string ="[a-zA-Z0-9]*";

    public static emailRegex: string ="[a-zA-Z0-9]*";

    public static contactNumberRegex: string ="^[e0-9]{10,10}$";

    public static error:string = "error";
    
}
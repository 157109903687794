import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SnakbarService } from '../services/snakbar.service';
import { UserService } from '../services/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  feedbackForm:any = FormGroup;
  locationId:any;

  constructor(
    private httpClient:HttpClient,
    private route: ActivatedRoute,
    private formBuilder:FormBuilder,
    private userService:UserService,
    private ngxService:NgxUiLoaderService,
    private snakbarService:SnakbarService,
    private router:Router) { }

  ngOnInit(): void {

    this.route.queryParams
    .subscribe(params => {
      this.locationId = params.locationId;
    }
  );

    this.feedbackForm = this.formBuilder.group({
      name:[null],
      phoneNumber:[null],
      message:[null],
      locationId: [null]
    });
  }


  handleSubmit() {
    this.ngxService.start();
    var formData = this.feedbackForm.value;
    var data = {
      name: formData.name,
      phoneNumber: formData.phoneNumber,
      message: formData.message,
      locationId: parseInt(this.locationId)
    };

    this.httpClient.post("https://salesmanager.store/api/feedback", data, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    }).subscribe((response:any) =>{
      this.ngxService.stop();
      this.router.navigate(['feedback/success']);
    }, 
    (error) =>{
      this.ngxService.stop();
      this.router.navigate(['feedback/success']);
    })
  }

}

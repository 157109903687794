<h2>Повний звіт</h2>

<mat-card style="display: flex; justify-content: center; align-items: center;">
    <strong style="white-space: nowrap;">Вибір періоду:</strong>
    <input type="text" name="daterange" ngxDaterangepickerMd [(ngModel)]="selected" class="form-control" (change)="dateChanged($event)" />
    <strong style=" white-space: nowrap;">Вибір закладу:</strong>
    <mat-select name="location" style="margin-left: 10px;" (selectionChange)="locationChagedAction($event)" [(ngModel)]="defaultLocation" >
        <mat-option *ngFor="let location of locations" [value]="location" >{{ location.name }}</mat-option>
    </mat-select>
</mat-card>
<br/>
<br/>

<div style="display: flex; justify-content: center; align-items: center;">
    <button style="" mat-raised-button (click)="handleReportExport()">Експортувати в Excel</button>
</div>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  url = environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  get(){
    return this.httpClient.get(this.url + "/admin/location", {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }
}

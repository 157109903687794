<mat-toolbar color="primary">
    <mat-toolbar-row fxLayout="row">
        <span>Вхід</span>
        <span class="spacer"></span>
        <button class="mat-dialog-close" mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="loginForm">
        <div fxFlex fxLayout="column">
        
            <mat-form-field appearance="standard" fxFlex>
                <mat-label>Імейл</mat-label>
                <input matInput formControlName="email" required>
                <mat-error *ngIf="loginForm.controls.email.touched && loginForm.controls.email.invalid">
                    <span *ngIf="loginForm.controls.email.errors.required">Це поле обовʼязкове</span>
                    <span *ngIf="loginForm.controls.email.errors.pattern">Це поле введено не корректно</span>
                </mat-error> 
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>Пароль</mat-label>
                <input matInput formControlName="password" type="password" required [type]="password ? 'password': 'text'">
                <button type="button" mat-icon-button matSuffix (click)="password = !password" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="password" >
                    <mat-icon>{{password ? 'visibility_off': 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="loginForm.controls.password.touched && loginForm.controls.password.invalid">
                    <span *ngIf="loginForm.controls.password.errors.required">Це поле обовʼязкове</span>
                </mat-error> 
            </mat-form-field>  

            <!-- <mat-form-field appearance="standard" fxFlex>
                <mat-label>Password</mat-label>
                <input matInput formControlName="password" required>
                <mat-error *ngIf="loginForm.controls.password.touched && loginForm.controls.password.invalid">
                    <span *ngIf="loginForm.controls.password.errors.required">This filed is mandatory</span>
                </mat-error> 
            </mat-form-field> -->

        </div>
    </form>
</mat-dialog-content>


<mat-dialog-actions align="end" >
    <button mat-raised-button color="primary" type="submit" (click)="handleSubmit()" [disabled]="!(loginForm.valid && loginForm.dirty)">
        <mat-icon>login</mat-icon>Увійти
    </button>
    <button mat-raised-button color="warn" mat-dialog-close>
        <mat-icon>cancel</mat-icon>Закрити
    </button>
</mat-dialog-actions>
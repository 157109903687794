<div class="wrapper sticky">
  <nav class="navbar-fixed-top">
    <a href="#" class="logo">
      <mat-icon>storefront</mat-icon> QuickSale POS
    </a>
    <ul>
      <li>
        <!-- <a (click)="handleLoginAction()">Login</a>
        <a (click)="handleSingupAction()">Singup</a>
        <a (click)="handleForgotPasswordAction()">Forgot password</a> -->
      </li>
    </ul>
  </nav>
</div>


<div style="text-align: center;">
  <button mat-raised-button color="primary" type="submit" (click)="handleLoginAction()" style="position: absolute; top: 50%; ">
    <mat-icon>login</mat-icon>Вхід в адмін панель
</button>
</div>
<mat-toolbar color="primary">
    <mat-toolbar-row fxLayout="row">
        <span>Signup</span>
        <span class="spacer"></span>
        <button class="mat-dialog-close" mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="signupForm">
        <div fxFlex fxLayout="column">
            <mat-form-field appearance="standard" fxFlex>
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstName" required>
                <mat-error *ngIf="signupForm.controls.firstName.touched && signupForm.controls.firstName.invalid">
                    <span *ngIf="signupForm.controls.firstName.errors.required">This filed is mandatory</span>
                    <span *ngIf="signupForm.controls.firstName.errors.pattern">This filed is invalid</span>
                </mat-error> 
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName" required>
                <mat-error *ngIf="signupForm.controls.lastName.touched && signupForm.controls.lastName.invalid">
                    <span *ngIf="signupForm.controls.lastName.errors.required">This filed is mandatory</span>
                    <span *ngIf="signupForm.controls.lastName.errors.pattern">This filed is invalid</span>
                </mat-error> 
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>Company Name</mat-label>
                <input matInput formControlName="companyName" required>
                <mat-error *ngIf="signupForm.controls.companyName.touched && signupForm.controls.companyName.invalid">
                    <span *ngIf="signupForm.controls.companyName.errors.required">This filed is mandatory</span>
                    <span *ngIf="signupForm.controls.companyName.errors.pattern">This filed is invalid</span>
                </mat-error> 
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" required>
                <mat-error *ngIf="signupForm.controls.email.touched && signupForm.controls.email.invalid">
                    <span *ngIf="signupForm.controls.email.errors.required">This filed is mandatory</span>
                    <span *ngIf="signupForm.controls.email.errors.pattern">This filed is invalid</span>
                </mat-error> 
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>Password</mat-label>
                <input matInput formControlName="password" type="password" required [type]="password ? 'password': 'text'">
                <button type="button" mat-icon-button matSuffix (click)="password = !password" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="password" >
                    <mat-icon>{{password ? 'visibility_off': 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="signupForm.controls.password.touched && signupForm.controls.password.invalid">
                    <span *ngIf="signupForm.controls.password.errors.required">This filed is mandatory</span>
                </mat-error> 
            </mat-form-field>

            <mat-form-field appearance="standard" fxFlex>
                <mat-label>Confirm Password</mat-label>
                <input matInput formControlName="confirmPassword" type="password" required [type]="confirmPassword ? 'password': 'text'">
                <button type="button" mat-icon-button matSuffix (click)="confirmPassword = !confirmPassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="password" >
                    <mat-icon>{{confirmPassword ? 'visibility_off': 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="signupForm.controls.confirmPassword.touched && signupForm.controls.confirmPassword.invalid">
                    <span *ngIf="signupForm.controls.confirmPassword.errors.required">This filed is mandatory</span>
                </mat-error> 
                <mat-hint *ngIf="validateSubmit() && !(signupForm.controls.confirmPassword.invalid)">
                    <span>New password & Confirm Password does not match</span>
                </mat-hint>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" >
    <button mat-raised-button color="primary" type="submit" (click)="handleSubmit()" [disabled]="validateSubmit() || !(signupForm.valid && signupForm.dirty)">
        <mat-icon>save</mat-icon> Signup
    </button>
    <button mat-raised-button color="warn" mat-dialog-close>
        <mat-icon>cancel</mat-icon>Close
    </button>
</mat-dialog-actions>
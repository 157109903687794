import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FiscalizationService } from 'src/app/services/fiscalization.service';
import { SnakbarService } from 'src/app/services/snakbar.service';
import { UserService } from 'src/app/services/user.service';
import { GlobalConstants } from 'src/app/shared/global-constanst';
import { SignupComponent } from 'src/app/signup/signup.component';

@Component({
  selector: 'app-add-key',
  templateUrl: './add-key.component.html',
  styleUrls: ['./add-key.component.scss']
})
export class AddKeyComponent implements OnInit {

  certName;
  keyName;
  certData;
  keyData;
  isTestingMode;
  password = true;
  confirmPassword = true;
  keyForm: any = FormGroup;
  responseMessage:any;

  constructor(private formBuilder:FormBuilder,
    private router:Router,
    private fiscalizationService:FiscalizationService,
    private snakbarService:SnakbarService,
    public dialogRef: MatDialogRef<SignupComponent>,
    private ngxService: NgxUiLoaderService) { }

    onCertSelected(event){
      const file:File = event.target.files[0];

      if (file) {
          this.certName = file.name;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.certData = reader.result.toString().split(',')[1];;
          };
      }
    }

    onKeySelected(event){
      const file:File = event.target.files[0];

      if (file) {
          this.keyName = file.name;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.keyData = reader.result.toString().split(',')[1];
          };
      }
    }

  ngOnInit(): void {
    this.keyForm = this.formBuilder.group(
      {
        fopName:[null, [Validators.required, Validators.pattern(GlobalConstants.nameRegex)]],
        password:[null, Validators.required],
        sendTestChecks:[null]
      }
    )
  }


  handleSubmit(){
    this.ngxService.start();
    var formData = this.keyForm.value;
   
    var data = {
      fopName: formData.fopName,
      keyName: this.keyName,
      keyData: this.keyData,
      certName: this.certName,
      certData: this.certData,
      password: formData.password,
      sendTestChecks: formData.sendTestChecks

    };

    this.fiscalizationService.addKey(data).subscribe((response:any) => {
      //TODO: call create location
      this.ngxService.stop();
      this.dialogRef.close();
      this.responseMessage = response?.message;
      // this.snakbarService.openSnackBar
      this.router.navigate(['/']);
    }, error => {
      this.ngxService.stop();
      if(error.error?.message){
        this.responseMessage = error.error.message;
      } else{
        this.responseMessage = GlobalConstants.genericError;
      }
      this.snakbarService.openSnackBar(this.responseMessage, GlobalConstants.error);
    })
  }

}

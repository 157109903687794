import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff-keys',
  templateUrl: './staff-keys.component.html',
  styleUrls: ['./staff-keys.component.scss']
})
export class StaffKeysComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<h2>Прибуток/збитки</h2>


<mat-card style="display: flex; justify-content: center; align-items: center;">
    <strong style="white-space: nowrap;">Вибір періоду:</strong>
    <input type="text" name="daterange" ngxDaterangepickerMd [(ngModel)]="selected" class="form-control" (change)="dateChanged($event)" />
    <strong style=" white-space: nowrap;">Вибір закладу:</strong>
    <mat-select name="location" style="margin-left: 10px;" (selectionChange)="locationChagedAction($event)" [(ngModel)]="defaultLocation" >
        <mat-option *ngFor="let location of locations" [value]="location" >{{ location.name }}</mat-option>
    </mat-select>
</mat-card>
<br/>
<br/>
<mat-card>
    <strong >Собівартість: </strong><span>{{manufacturingPrice}} ₴</span>
    <br/>
    <strong >Маржа: </strong><span>{{margin}} ₴</span>
    <br/>
    <strong >Чистий прибукток: </strong><span>{{netProfit}} ₴</span>
    <br/>
    <strong >Ефективність: </strong><span>{{saleRevenue}} %</span>
</mat-card>
<br/>

<mat-expansion-panel (opened)="incomePanelOpenState = true"
(closed)="incomePanelOpenState = false"  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong style="font-size: large;">Дохід: {{totalIncome}} ₴</strong>
      </mat-panel-title>
  
    </mat-expansion-panel-header>
    <button style="float: right;" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Дохід', sheet: 'Дохід'})">Експортувати в Excel</button>
    <br/>
    <br/>
    <div class="responsive_table">
        <table mat-table matTableExporter [dataSource]="incomeDataSource" #exporter="matTableExporter"  class="mat-elevation-z8">
    
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
          
            <!-- Position Column -->
            <ng-container matColumnDef="productName">
              <th mat-header-cell *matHeaderCellDef> Товар </th>
              <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
            </ng-container>
          
            <!-- Name Column -->
            <ng-container matColumnDef="tags">
              <th mat-header-cell *matHeaderCellDef> Теги </th>
              <td mat-cell *matCellDef="let element"> {{element.tags}} </td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="manufacturingPrice">
              <th mat-header-cell *matHeaderCellDef> Собівартість </th>
              <td mat-cell *matCellDef="let element"> {{element.manufacturingPrice}} ₴ </td>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="margin">
              <th mat-header-cell *matHeaderCellDef> Маржа</th>
              <td mat-cell *matCellDef="let element"> {{element.margin}}  ₴</td>
            </ng-container>
    
              <!-- Symbol Column -->
              <ng-container matColumnDef="totalAmount">
                <th mat-header-cell *matHeaderCellDef> Сумма </th>
                <td mat-cell *matCellDef="let element"> {{element.totalAmount}} ₴</td>
              </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="utilizedAmount">
                <th mat-header-cell *matHeaderCellDef> Списано на сумму </th>
                <td mat-cell *matCellDef="let element"> {{element.utilizedAmount}} ₴</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="incomeColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: incomeColumns;"></tr>
          
            <!-- Group header -->
            <ng-container matColumnDef="groupHeader">
              <td colspan="999"  mat-cell *matCellDef="let groupBy">
                <strong class="left">{{groupBy.categoryName}}</strong><strong style="float: right; padding-right: 130px;">{{groupBy.totalAmount}} ₴</strong>
            </td>
            </ng-container>
          
            <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>
          
          </table>
          
    </div>
  </mat-expansion-panel>

<br/>
<mat-expansion-panel (opened)="outcomePanelOpenState = true"
(closed)="outcomePanelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong style="font-size: large;">Витрати: {{totalOutcome}} ₴</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <button style="float: right;" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Витрати', sheet: 'Витрати'})">Експортувати в Excel</button>
    <br/>
    <br/>
    <div class="responsive_table">
        <table mat-table matTableExporter [dataSource]="outcomeDataSource" #exporter="matTableExporter"  class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
          
            <!-- Position Column -->
            <ng-container matColumnDef="expenseName">
              <th mat-header-cell *matHeaderCellDef> Найменування </th>
              <td mat-cell *matCellDef="let element"> {{element.expenseName}} </td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="totalAmount">
              <th mat-header-cell *matHeaderCellDef> Сумма </th>
              <td mat-cell *matCellDef="let element"> {{element.totalAmount}} ₴</td>
            </ng-container>
          
          
            <tr mat-header-row *matHeaderRowDef="outcomeColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: outcomeColumns;"></tr>
          
            <!-- Group header -->
            <ng-container matColumnDef="groupHeader">
              <td colspan="999"  mat-cell *matCellDef="let groupBy">
                <strong class="left">{{groupBy.expenseFinancialType}}</strong><strong style="float: right; padding-right: 180px;">{{groupBy.totalAmount}} ₴</strong>
            </td>
            </ng-container>
          
            <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>
          
          </table>
    </div>
  </mat-expansion-panel>
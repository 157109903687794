import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material-module';
import { HomeComponent } from './home/home.component';
import { BestSellerComponent } from './best-seller/best-seller.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from './shared/shared.module';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SignupComponent } from './signup/signup.component';
import { NgxUiLoaderConfig, NgxUiLoaderModule, SPINNER } from 'ngx-ui-loader';
import { HttpResponceInterceptor } from './interceptors/http-response.interceptor';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { HttpAuthInterceptor } from './interceptors/http-auth.interceptor';
import { AuthGuardService } from './services/auth-guard.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FinanceComponent } from './finance/finance.component';
import { StatisticComponent } from './statistic/statistic.component';
//import { FiscalizationComponent } from './fiscalization/fiscalization.component';
import { CashFlowComponent } from './statistic/cash-flow/cash-flow.component';
import { PnlComponent } from './statistic/pnl/pnl.component';
import { ProductComponent } from './statistic/product/product.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatTableExporterModule } from 'mat-table-exporter';
import { FullReportComponent } from './statistic/full-report/full-report.component';
import { SalepointsComponent } from './fiscalization/salepoints/salepoints.component';
//import { CashiersComponent } from './fiscalization/cashiers/cashiers.component';
import { StaffKeysComponent } from './fiscalization/staff-keys/staff-keys.component';
import { AddKeyComponent } from './fiscalization/add-key/add-key.component';
import { UpdateKeyComponent } from './fiscalization/update-key/update-key.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { SuccessComponent } from './feedback/success/success.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  text: "Loading...",
  textColor:"#FFFFFF",
  textPosition:'center-center',
  bgsColor:'#43a047',
  fgsColor:'#43a047',
  fgsType: SPINNER.squareJellyBox,
  fgsSize:100,
  hasProgressBar:false
}

@NgModule({
  declarations: [	
    AppComponent,
    HomeComponent,
    BestSellerComponent,
    FullComponent,
    AppHeaderComponent,
    AppSidebarComponent,
    SignupComponent,
    ForgotPasswordComponent,
    LoginComponent,
    FinanceComponent,
    StatisticComponent,
    // FiscalizationComponent,
    CashFlowComponent,
    PnlComponent,
    ProductComponent,
    FullReportComponent,
    SalepointsComponent,
    // CashiersComponent,
    StaffKeysComponent,
    AddKeyComponent,
    UpdateKeyComponent,
    FeedbackComponent,
    SuccessComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    SharedModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgbModule,
    NgxDaterangepickerMd.forRoot(),
    MatTableExporterModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponceInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true
    },
    AuthGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

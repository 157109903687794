<mat-nav-list>
    <mat-list-item routerLink="finance">
        <span>Фінанси</span>
    </mat-list-item>
    <mat-list-item >
    <mat-expansion-panel [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header><span>Статистика</span>
        </mat-expansion-panel-header>
        <mat-nav-list>
            <a mat-list-item routerLink="statistic/cashFlow">
                <span>
                    Рух грошових коштів
                </span>
            </a>
            <a mat-list-item routerLink="statistic/pnl">
                <span>
                    Прибуток/збитки
                </span>
            </a>
            <a mat-list-item routerLink="statistic/product">
                <span>
                    Прибутковість товарів
                </span>
            </a>
            <a mat-list-item routerLink="statistic/full-report">
                <span>
                    Повний звіт
                </span>
            </a>
        </mat-nav-list>
    </mat-expansion-panel>
    </mat-list-item>
    <mat-list-item routerLink="fiscalization">
       <span>ПРРО</span>
    </mat-list-item>
</mat-nav-list>
<!doctype html>
<html lang="en">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Вiдгук про кав'ярню CoffeeRoom</title>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.1/css/bulma.min.css">
    <link rel="icon" href="/favicon.png" sizes="32x32">
</head>
<body>
<div id="nav-container"></div>
<section class="hero is-fullheight">
    <div class="hero-body">
        <div class="container">
            <div class="columns is-vcentered">
                <div id="main" class="column is-half is-centered has-nice-link">
                    <!-- <img id="app-icon"" width="128" height="128"> -->
                    <h1 class="title is-2">Дякуємо!</h1>
                    <h2 class="title is-6">Ми обов'язково опрацюємо ваш відгук. Гарного дня!</h2>
                </div>
            </div>
        </div>
    </div>
</section>
</body>
</html>

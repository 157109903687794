import {HttpEvent, HttpInterceptor as Interceptor, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { SnakbarService } from '../services/snakbar.service';
import {Router} from '@angular/router';
import { GlobalConstants } from '../shared/global-constanst';

@Injectable()
export class HttpResponceInterceptor implements Interceptor {

    private readonly BusinessException = 2;
    private readonly BusinessExceptionText = 'businessException';

    constructor(private shakbarService: SnakbarService,
        private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any> {    
        return next
          .handle(request.clone({}))
          .pipe(
            map((response: HttpEvent<any>) => {

                var responseBody: any;
                if (response instanceof HttpResponse) {
                    responseBody = response.body;
                } else{
                    return response;
                }
    
                if (!(<HttpResponse<any> | Object>response instanceof HttpResponse) || !responseBody || !responseBody.hasOwnProperty('Data')) {
                    return response;
                }

                if (responseBody.Errors && responseBody.Errors.length > 0) {
                  const errors = responseBody.Errors;
      
                  errors.forEach((error:any) => {
                    console.log(error.FullMessage ?? error.Message);

                    if (responseBody.StatusCode  === this.BusinessException || error.Code === this.BusinessException || error.Code === this.BusinessExceptionText) {
                      this.shakbarService.openSnackBar(error.FullMessage ?? error.Message, GlobalConstants.error);
                    } else{
                      this.shakbarService.openSnackBar('Server error', GlobalConstants.error);
                    }
                  });
                  throw errors;
                }
    
                
                if (responseBody.Data !== null) {
                    return response.clone({ body: responseBody.Data });
                } else {
                    return response;
                }
            }),
            catchError((response) => {
              if (response && response.status === 401) {
                // throw authService.loginRedirect();
              }
    
              if (response && response.status === 403) {
                this.router.navigate(['/errors/403']);
                throwError('You do not have permission to view this site');
              }
    
              if (response.error && response.error.Errors && response.error.Errors.length > 0) {
                const errors = response.error.Errors;
    
                if (response.error.StatusCode === this.BusinessException || response.error.StatusCode === this.BusinessExceptionText) {
                  errors.forEach((error:any) => {
                    console.log(error.FullMessage ?? error.Message)
                    this.shakbarService.openSnackBar(error.FullMessage ?? error.Message, GlobalConstants.error);
                  });
                } else {
                   this.shakbarService.openSnackBar('Server error', GlobalConstants.error);
                }
              }
    
              return throwError(response);
            })
          );
      }
    }
    
<h2>Прибутковість товарів</h2>

<mat-card style="display: flex; justify-content: center; align-items: center;">
    <strong style="white-space: nowrap;">Вибір періоду:</strong>
    <input type="text" name="daterange" ngxDaterangepickerMd [(ngModel)]="selected" class="form-control" (change)="dateChanged($event)" />
    <strong style=" white-space: nowrap;">Вибір закладу:</strong>
    <mat-select name="location" style="margin-left: 10px;" (selectionChange)="locationChagedAction($event)" [(ngModel)]="defaultLocation" >
        <mat-option *ngFor="let location of locations" [value]="location" >{{ location.name }}</mat-option>
    </mat-select>
</mat-card>
<br/>

<button style="float: right;" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Товари', sheet: 'Товари'})">Експортувати в Excel</button>
<br/>
<br/>
<div class="responsive_table">
    <table mat-table matTableExporter [dataSource]="productsDataSource" #exporter="matTableExporter"  class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
      
        <!-- Position Column -->
        <ng-container matColumnDef="productName">
          <th mat-header-cell *matHeaderCellDef> Товар </th>
          <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="tags">
          <th mat-header-cell *matHeaderCellDef> Теги </th>
          <td mat-cell *matCellDef="let element"> {{element.tags}} </td>
        </ng-container>
      
        <!-- Symbol Column -->
        <ng-container matColumnDef="earnedAmount">
          <th mat-header-cell *matHeaderCellDef> Маржа</th>
          <td mat-cell *matCellDef="let element"> {{element.earnedAmount}}  ₴</td>
        </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="totalAmount">
            <th mat-header-cell *matHeaderCellDef> Сумма </th>
            <td mat-cell *matCellDef="let element"> {{element.totalAmount}} ₴</td>
          </ng-container>
      


        <tr mat-header-row *matHeaderRowDef="productsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: productsColumns;"></tr>
      
        <!-- Group header -->
        <ng-container matColumnDef="groupHeader">
          <td colspan="999"  mat-cell *matCellDef="let groupBy">
            <strong class="left">{{groupBy.categoryName}}</strong><strong style="float: right; padding-right: 50px;">{{groupBy.totalAmount}} ₴</strong>
        </td>
        </ng-container>
      
        <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>
      
      </table>
      
</div>
import { Injectable } from '@angular/core';
import { CanActivate, Router, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from './user.service';

const jwtHelper = new JwtHelperService();

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
    constructor(private router: Router, private userService: UserService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var token = localStorage.getItem("token");
        
        if (!token || token === "undefined") {
            if (state.url == "/finance") {
                this.router.navigate(['/']);
            } else {
                this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
            }
        }

        if (token !== "undefined" && !jwtHelper.isTokenExpired(token)){
            return true;
        }
        const isRefreshSuccess = await this.userService.tryRefreshingTokens(token); 
        if (!isRefreshSuccess) { 
            this.router.navigate(["/"]); 
        }
        return isRefreshSuccess;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.canActivate(childRoute, state);
    }

  
}

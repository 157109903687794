<!doctype html>
<html lang="en">
	<head>
		<meta charset="utf-8">
		<meta name="viewport" content="width=device-width, initial-scale=1">
		<title>Вiдгук про кав'ярню CoffeeRoom</title>
		<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.1/css/bulma.min.css">
		<!-- <link rel="stylesheet" href="main.css">
		<link rel="icon" href="/favicon.png" sizes="32x32"> -->
		<script src="https://code.jquery.com/jquery-3.5.1.min.js" crossorigin="anonymous"></script>
		<!-- <script defer src="main.js"></script> -->
        <!-- <script>
            (() => {
	const apiUrl = "https://salesmanager.store/api/feedback"

	// Form validation
	$('button[type="submit"]').on('click', event => {
		$(event.target)
			.closest('form')
			.find('[required]')
			.addClass('required');
	});

	const params = new URL(location.href).searchParams;
	const form = $('#feedback-form');
	form.attr('action', apiUrl);
	// Include all the existing search params
	for (const [key, value] of params) {
		form.append(
			$(`<input type="hidden" name="${key}">`).val(value)
		);
	}
})();
        </script> -->
	</head>
	<body>
		<div id="nav-container"></div>
		<section class="hero is-fullheight">
			<div class="hero-body">
				<div class="container">
					<div class="columns is-vcentered">
						<div id="main" class="column is-half is-centered has-nice-link">
							<!-- <img id="app-icon"" width="128" height="128"> -->
							<h1 class="title is-2">Привiт!</h1>
							<h2 class="title is-6">Будемо вдячнi за вiдгук 🙌</h2>
							<div id="additional-info"></div>
							<form [formGroup]="feedbackForm">
			
								<div class="field">
									<label class="label" for="name">Iм'я </label>
									<div class="control">
										<input class="input is-medium" type="text" placeholder="Як до вас звертатись?" formControlName="name" name="name" autocomplete="name">
									</div>
								</div>
								<div class="field">
									<label class="label" for="phoneNumber">Телефон</label>
									<div class="control">
										<input class="input is-medium" placeholder="Не обов'язково" type="text" formControlName="phoneNumber"  name="phoneNumber" autocomplete="phoneNumber">
									</div>
								</div>
								<div class="field">
									<label class="label" for="message">Питання/Побажання</label>
									<div class="control">
										<textarea class="textarea is-medium" placeholder="Оцініть нашу роботу" formControlName="message"  name="message" rows="7" autofocus></textarea>
									</div>
								</div>
								<br>
								<div class="field">
									<div class="control">
										<button class="button is-medium is-primary is-info" type="submit" (click)="handleSubmit()">Вiдправити</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	</body>
</html>

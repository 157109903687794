import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { SnakbarService } from '../services/snakbar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalConstants } from '../shared/global-constanst';
import { error } from 'console';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  password = true;
  confirmPassword = true;
  signupForm: any = FormGroup;
  responseMessage:any;

  constructor(private formBuilder:FormBuilder,
    private router:Router,
    private userService:UserService,
    private snakbarService:SnakbarService,
    public dialogRef: MatDialogRef<SignupComponent>,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.signupForm = this.formBuilder.group(
      {
        firstName:[null, [Validators.required, Validators.pattern(GlobalConstants.nameRegex)]],
        lastName:[null, [Validators.required, Validators.pattern(GlobalConstants.nameRegex)]],
        companyName:[null, [Validators.required, Validators.pattern(GlobalConstants.nameRegex)]],
        email:[null, [Validators.required, Validators.pattern(GlobalConstants.emailRegex)]],
        password:[null, Validators.required],
        confirmPassword:[null, Validators.required]
      }
    )
  }

  validateSubmit(){
    if(this.signupForm.controls['password'].value != this.signupForm.controls['confirmPassword'].value){
      return true;
    } else{
      return false;
    }
  }

  handleSubmit(){
    this.ngxService.start();
    var formData = this.signupForm.value;
    var locale = this.getUsersLocale("uk-ua");
    var data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      companyName: formData.companyName,
      email: formData.email,
      password: formData.password,
      culture: locale,
    };

    this.userService.signup(data).subscribe((response:any) => {
      //TODO: call create location
      this.ngxService.stop();
      this.dialogRef.close();
      this.responseMessage = response?.message;
      // this.snakbarService.openSnackBar
      this.router.navigate(['/']);
    }, error => {
      this.ngxService.stop();
      if(error.error?.message){
        this.responseMessage = error.error.message;
      } else{
        this.responseMessage = GlobalConstants.genericError;
      }
      this.snakbarService.openSnackBar(this.responseMessage, GlobalConstants.error);
    })
  }

  getUsersLocale(defaultValue: string): string {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return defaultValue;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : defaultValue;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
    return lang;
  }

}

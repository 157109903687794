import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FiscalizationService {

  url = environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getKeys(){
    return this.httpClient.get(this.url + "/admin/fiscalization/keys", {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  getStaffKeys(keyId){
    return this.httpClient.get(this.url + "/admin/fiscalization/keys/" + keyId + "/staff", {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  getStaffWorkingHours(keyId){
    return this.httpClient.get(this.url + "/admin/fiscalization/keys/" + keyId + "/working-hours", {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  getFiscalizedAmount(keyId){
    return this.httpClient.get(this.url + "/admin/fiscalization/keys/" + keyId + "/fiscalized-amount", {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  addKey(data:any){
    return this.httpClient.post(this.url + "/admin/fiscalization/keys", data, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  addStaffKey(keyId, data:any){
    return this.httpClient.post(this.url + "/admin/fiscalization/keys/" + keyId + "/staff", data, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  updateKey(keyId, data:any){
    return this.httpClient.put(this.url + "/admin/fiscalization/keys/" + keyId, data, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  refreshKey(keyId){
    return this.httpClient.post(this.url + "/admin/fiscalization/keys/" + keyId + "/refresh", {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  updateStaffKey(staffKeyId, data:any){
    return this.httpClient.put(this.url + "/admin/fiscalization/keys/staff/" + staffKeyId, data, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  deleteKey(keyId){
    return this.httpClient.delete(this.url + "/admin/fiscalization/keys/" + keyId, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  deleteStaffKey(keyId){
    return this.httpClient.delete(this.url + "/admin/fiscalization/keys/staff/" + keyId, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  toggleSendFiscalizationChecks(salePointId){
    return this.httpClient.post(this.url + "/admin/fiscalization/salepoints/" + salePointId, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  toggleRegistarActive(id){
    return this.httpClient.post(this.url + "/admin/fiscalization/registars/" + id, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  setSalePointLocationMapping(data:any){
    return this.httpClient.post(this.url + "/admin/fiscalization/salepoints/mapping", data, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }
}

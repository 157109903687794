import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { from } from "rxjs";


const jwtHelper = new JwtHelperService();


@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private userService: UserService){
        
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handleAccess(request, next))
    }

    private async handleAccess(request: HttpRequest<any>, next: HttpHandler):
        Promise<HttpEvent<any>> {

        let token = localStorage.getItem("token");

        if (token !== "undefined" && !jwtHelper.isTokenExpired(token)){
            const cloned = request.clone({
                headers: request.headers.set("Authorization", "Bearer " + token)
            });

            return next.handle(cloned).toPromise();
        }

        if(request.url.indexOf('users/authenticate') == 0 && request.url.indexOf('users/refresh-token') == 0)
        {
            const isRefreshSuccess = await this.userService.tryRefreshingTokens(token); 
            if (!isRefreshSuccess) { 
                this.router.navigate(["/"]); 
            }
            else{
                token = localStorage.getItem("token");
                const cloned = request.clone({
                    headers: request.headers.set("Authorization", "Bearer " + token)
                });
                return next.handle(cloned).toPromise();
            }
        }

        return next.handle(request).toPromise();
    }
}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatisticService {

  url = environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getCashFlow(locationId:any, start:any, end:any){
    let endpointUrl = this.url + "/admin/statistic/cashflow?start=" + start + "&end=" + end
    if(locationId !== undefined && locationId !== null){
        endpointUrl += "&locationId=" + locationId;
    }
    return this.httpClient.get(endpointUrl, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  getPnl(locationId:any, start:any, end:any){
    let endpointUrl = this.url + "/admin/statistic/pnl?start=" + start + "&end=" + end
    if(locationId !== undefined && locationId !== null){
        endpointUrl += "&locationId=" + locationId;
    }
    return this.httpClient.get(endpointUrl, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  getProducts(locationId:any, start:any, end:any){
    let endpointUrl = this.url + "/admin/statistic/product?start=" + start + "&end=" + end
    if(locationId !== undefined && locationId !== null){
        endpointUrl += "&locationId=" + locationId;
    }

    return this.httpClient.get(endpointUrl, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  getFullReport(locationId:any, start:any, end:any){
    let endpointUrl = this.url + "/admin/statistic/export?start=" + start + "&end=" + end
    if(locationId !== undefined && locationId !== null){
        endpointUrl += "&locationId=" + locationId;
    }
    return this.httpClient.get(endpointUrl, {
      // headers: new HttpHeaders().set('Content-Type', 'application/octet-stream')
      responseType: 'blob' as 'json'
    })
  }
}

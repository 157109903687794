import { Component, OnInit } from '@angular/core';
import { StatisticService } from '../services/statistic.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SnakbarService } from '../services/snakbar.service';

@Component({
  selector: 'app-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.scss']
})
export class StatisticComponent implements OnInit {

  constructor(statisticService:StatisticService,
    private ngxService:NgxUiLoaderService,
    private snakbarService:SnakbarService,) { }

  ngOnInit(): void {
  }

}

import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {

  constructor(private router:Router) {
  }

  handleLogout(){
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("refreshToken");
    this.router.navigate(['/']);
  }
}
